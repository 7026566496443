import { Button, Result, Spin } from "antd"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import useAuth from "../components/use-auth"

export default () => {
  const auth = useAuth()
  const [error, setError] = useState(null)

  useEffect(() => {
    // FIXME: P1 remove null error check to avoid infinite re-render
    if (error === null) {
      auth
        .handleAuthentication()
        .then(
          ({ appState }) => navigate(appState ? appState.redirectUrl : "/"),
          err => setError(err)
        )
    }
  }, [error])

  return error ? (
    <>
      <SEO title="Authentication Error" />
      <Result
        status="warning"
        title={`${error.error}: ${error.errorDescription}`}
        subTitle="If the problem persist, please contact our administrator."
        extra={
          <Button type="primary" onClick={() => auth.login()}>
            Re-Login
          </Button>
        }
      />
    </>
  ) : (
    <>
      <SEO title="Authenticating" />
      <Spin size="large" tip="Loading..." style={{ maxHeight: "none" }}>
        <div style={{ height: "100vh" }} />
      </Spin>
    </>
  )
}
